@import "@taiga-ui/core/styles/mixins/mixins";
@primary: rgba(239, 125, 0, 1);
@second-primary: #263238;
@popup-background: #F1F3F9;
@main-background: #ECEFF1;
@disabled: #A7A8A9;
//@success: var(--nui-success);
//@error: var(--nui-error);
//@neutral: var(--nui-neutral);

tui-dialog[data-size='l'] {
  width: 77rem  !important;
}
body {
  //--tui-error-fill: #9F2F0C;
  //--tui-error-bg: rgba(159, 47, 12, 0.9);
  //--tui-error-bg-hover: rgba(159, 47, 12, 1);


  --tui-neutral-bg: rgba(121, 129, 140, 0.24);
  --tui-neutral-bg-hover: rgba(121, 129, 140, 0.32);

  --tui-success-bg: rgba(74, 201, 155, 0.24);
  --tui-success-bg-hover: rgba(74, 201, 155, 0.32);


  --tui-error-bg:rgba(244, 87, 37, 0.24);
  --tui-error-bg-hover:rgba(244, 87, 37, 0.32);


  //#B75F43; #FA815B	FAA185; or more dark C6461E; 81260A; 954D36
  --tui-negative: #81260A;
  --tui-negative-hover: #612E1E; // 612E1E or 541703
  --tui-negative-night: #C0765F;
  --tui-base-04: #607D8B;
  --tui-negative-night-hover: #C05B3B;
  //--tui-primary: #000;
  --tui-primary: @second-primary;
  --tui-primary-hover: #282828;
  --tui-primary-active: #1E1E1E;
  --tui-accent: #EF7D00;
  --tui-secondary: #A7A8A9;
  //--tui-link: #607D8B;
  //--tui-link-hover: #545f64;
  --tui-secondary-hover: #D2D3D4;
  --tui-secondary-active: #A7A8A9;
  --tui-link: #ccc;
  --tui-link-hover: #EF6C00;
  //--tui-secondary-hover: #3e3c3c;
  //--tui-secondary-active: #2a2828;
  //--tui-base-01: transparent;

  /*
  NEKTA UI colors
   */
  --nui-primary: #e99941;
  --nui-border-lightgrey: #e7e7e7;
  --nui-error: #d70707;
  --nui-success: #43b517;
  --nui-warning: #ffc700;
  --nui-neutral: #79818c;
  --nui-no-connection: rgba(121, 129, 140, 0.24);
}
// be careful! --nui-success was :#3aa981, --nui-error was :#dd4c1e, --nui-neutral was :#79818c used by button appearance='green','red', 'grey'

//используется для фильтра в таблицах и для списка справочников
tui-wrapper[data-appearance='transparent'] {
  background: transparent;
  &[data-state='hovered'] {
    color: @primary;
  }
  &[data-state='pressed']{
    color: @primary;
    background: rgba(0, 0, 0, 0.1);
  }
}
tui-dialog[data-size="popup"]{
  & > .t-content {
    padding: 16px;
    width: 77vw !important;
    background: @popup-background;
    @media (max-width: 767px) {
      width: 100vw !important;
    }
  }
}

tui-dialog[data-size="right"] {
  position: absolute;
  border-radius: 0;
  border: 0;
  top: 0;
  right: 0;
  & > .t-close {
    color: #8284a4;
    background: #ffffff;
  }
  & > .t-content {
    padding: 16px;
    width: 77vw !important;
    background: @popup-background;
    min-height: 100vh;
    @media (max-width: 767px) {
      width: 100vw !important;
    }
  }
}
//используется для кнопки Изменить с иконкой карандаша
tui-wrapper[data-appearance='transparentIconAndText'] {
  background: transparent;
  color: @second-primary;
  &[data-state='hovered'] {
    color: @primary;
  }
  &[data-state='pressed']{
    color: #ac600b;
  }
}

tui-wrapper[data-appearance='transparentIcon'] {
  color: #000000;
  background: transparent;

  &[data-state='hovered'] {
    color: @primary;
    background: #ddd;
  }

  &[data-state='pressed'] {
    color: #ac600b;
    background: #ccc;
  }

  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-wrapper[data-appearance='menu'] {
  background: transparent;
  color: var(--tui-link);
  &[data-state='hovered'] {
    color: @primary;
    background: rgba(0, 0, 0, 0.3);
  }
  &[data-state='pressed']{
    color: @primary;
    background: rgba(0, 0, 0, 0.4);
  }
}

tui-wrapper[data-appearance='iconHovered'] {
  background: transparent;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
  & tui-svg{
    -webkit-transition:filter 0.5s;
    transition: filter 0.5s;
  }

  //& .t-wrapper{
  //  padding: 0.5rem 0.5rem;
  //}
  &[data-state='hovered'] {
    background: @second-primary;
    padding: 0.5rem 0.5rem;
    & tui-svg{
      filter:invert(100%);
    }
  }
  &[data-state='pressed']{
    background: @second-primary;
    opacity: 0.9;
    & tui-svg{
      filter:invert(100%);
    }
  }
}

//tui-wrapper[data-appearance='flat'] {
//  &.t-wrapper{
//    background: transparent;
//  }
//  & :hover:not(._no-hover){
//    //background: rgba(0, 0, 0, 0.2);
//    background: transparent;
//
//  }
//  //background: transparent;
//  &[data-state='hovered'] {
//    //background: rgba(0, 0, 0, 0.2);
//    background: transparent;
//  }
//  &[data-state='pressed']{
//    //background: rgba(0, 0, 0, 0.3);
//    background: transparent;
//  }
//  &._active:not(:hover) {
//    background: rgba(0, 0, 0, 0.2);
//
//    & tui-svg {
//      color: var(--tui-link-hover);
//    }
//
//    & span {
//      color: var(--tui-link-hover);
//    }
//  }
//}

/*// используется для ячейки скважин в графе на странице месторождений с событиями
tui-wrapper[data-appearance='dashboard'] {
  background: var(--nui-success);
  color: #000000;
  //border: var(--tui-primary) solid 1px;
  .shadow();
  &[data-state='hovered'] {
    //background: rgba(58, 169, 130, 0.8);
    opacity: 0.7;
    //border: var(--tui-primary-hover) solid 1px;
    //background: fade(@success, 50%);
  }

  &[data-state='pressed'] {
    //background: rgba(58, 169, 130, 0.5);
    opacity: 0.9;
    //border: var(--tui-primary-active) solid 1px;
  }

  &[data-state='disabled'] {
    background: @disabled;
  }
}*/

tui-wrapper[data-appearance='whiteblock-active-red'] {
  background: transparent;
  color: #000000;
  border: var(--tui-negative) solid 2px;

  &[data-state='hovered'] {
    background: var(--tui-support-05);
    color: #000000;
  }

  &[data-state='pressed'] {
    background: var(--tui-support-06);
  }

  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-wrapper[data-appearance='accent'] {
  color: #000000;
  background: transparent;

  &[data-state='hovered'] {
    background: #F79E3E;
  }

  &[data-state='pressed'] {
    background: #EF7100;
  }

  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-wrapper[data-appearance='flat-black-text'] {
  background: transparent;

  &[data-state='hovered'] {
    background: var(--tui-secondary-hover);
  }

  &[data-state='pressed'] {
    background: var(--tui-secondary-active);
  }

  &[data-state='disabled'] {
    background: @disabled;
  }
}

