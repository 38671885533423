@import 'assets/styles/colors.less';
@import "@taiga-ui/core/styles/variables/media";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  background: #ECEFF1;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
}

.t-wrapper > span {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media @mobile-m {
  .hidden-xs {
    display: none;
  }
}

//button{
//  background: none;
//  ._active{
//    background: none;
//  }
//}


.common-button {
  width: auto;
  padding: 10px 21px;
  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: @primary;
    border: 2px solid @primary;
  }

  &:active {
    background: @primary;
    color: #ffffff;
    border-color: @primary;
  }

  &.active {
    background: @primary;
    color: #ffffff;
    border-color: @primary;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%);
}

.light-mode .mat-flat-button.mat-primary {
  background: @primary;
}

.light-mode .mat-progress-spinner circle, .light-mode .mat-spinner circle {
  stroke: @primary;
}

.leaflet-control-attribution {
  display: none;
}

tui-alert {
  //width: 50vw;
  //width: calc(200px + (600 - 200) * ((100vw - 500px) / (1920 - 500))) !important;
}
tui-alert {
  //box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 16%);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 61%);
  display: block;
  border-radius: var(--tui-radius-m);
  width: 100%;
  margin: 0;
  width: 50vw !important;
  margin: 2rem 0 0 !important;;
/*   width: 18rem;
   margin: 2rem 3rem 0 auto;*/
}

tui-alert-host>div.t-notifications-wrapper{
position: fixed;
top: 0;
left: 50%;
display: flex;
width: 100%;
height: 100%;
flex-direction: column;
pointer-events: none;
}
tui-notification {
padding-left: 3rem;
@media (min-width: 2200px) {
padding-left: 6rem !important;
}

.t-heading[automation-id="tui-notification-alert__heading"],
.t-content[automation-id="tui-notification-alert__content"] {
font-size: calc(15px + (30 - 15) * ((100vh - 500px) / (1920 - 500)));
line-height: normal;
}

.t-content[automation-id="tui-notification-alert__content"] {
font-size: calc(10px + (30 - 15) * ((100vh - 500px) / (1920 - 500)));
}

tui-svg[automation-id="tui-notification__icon"] {
top: 0.625rem;
right: 23.5rem;
}
}


button[automation-id="tui-notification__close"] {
@media (min-width: 2200px) {
width: 35px !important;
height: 35px !important;
}
@media (min-width: 3200px) {
width: 50px !important;
height: 50px !important;
}

.ng-star-inserted {
font-size: calc(10px + (22 - 10) * ((100vw - 500px) / (1920 - 500)));
}
}

:root {
--warning-notification-color: #fff8e0;
--success-notification-color: green;
--error-notification-color: red;
--notification-label-font-size: 24px;
--notification-content-font-size: 14px;
--notification-label-font-style: normal;
--notification-label-font-weight: 500;
--notification-content-font-style: normal;
--notification-content-font-weight: 500;
  --triangle-color-ppd: #1717a7;
  --triangle-color-shgn: #832f2f;
  --triangle-color-shvn: #ffff00;
  --triangle-color-ecn: #6100fb;
  --triangle-color-repair: #ffffff;
  --triangle-color-no-type-mining: #525860;

  --background-color-ppd: #0ba9d9;
  --color_status_ok: #43b517;
  --color_status_no_connection: #79818c;
  --color_status_stop: #d70707;

  --font_color_status_ok: #000;
  --font_color_status_ppd: #000;
  --font_color_status_no_connection: #000;
  --font_color_status_stop: #000;

  --color_water_pipeline: #20aaf5;
  --color_oil_pipeline: #000000;
 --color_electricity_pipeline: #f5b820;
}

.pipelines-color__button_aqua{
  background: white !important;
  color: black;
  &:hover{
    background: var(--color_water_pipeline) !important;
  }
  &::after{
    content: '';
    position: relative;
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
    mix-blend-mode: exclusion;
    text-transform: uppercase;
    }
    &._selected{
      background: var(--color_water_pipeline) !important;
    }
}

.pipelines-color__button_oil{
  background: white !important;
  color: black;
  &:hover{
    color: white;
    background: var(--color_oil_pipeline) !important;
  }
  &::after{
  content: '';
  position: relative;
  width: 10px;
  height: 10px;
  right: 0;
  top: 0;
  mix-blend-mode: exclusion;
  text-transform: uppercase;
  }
  &._selected{
    color: white;
    background: var(--color_oil_pipeline) !important;
  }
}

.pipelines-color__button_electricity{
  background: white !important;
  color: black;
  &:hover{
    background: var(--color_electricity_pipeline) !important;
  }

  &::after{
    content: '';
    position: relative;
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
    mix-blend-mode: exclusion;
    text-transform: uppercase;
    }
    &._selected{
      background: var(--color_electricity_pipeline) !important;
    }
}


.borehole_type_ppd{
  background-color: var(--triangle-color-ppd);
  &:before{
    color: var(--triangle-color-ppd);
  }
  &.status_ok{
    background-color: var(--background-color-ppd);
    color: var(--font_color_status_ppd)
  }
}
.borehole_type_shgn{
  background-color: var(--triangle-color-shgn);
  &:before{
    color: var(--triangle-color-shgn);
  }
}
.borehole_type_shvn{
  background-color: var(--triangle-color-shvn);
  &:before{
    color: var(--triangle-color-shvn);
  }
}
.borehole_type_ecn{
  background-color: var(--triangle-color-ecn);
  &:before{
    color: var(--triangle-color-ecn);
  }
}
.borehole_type_repair{
  background-color: var(--triangle-color-repair);
  &:before{
    color: var(--triangle-color-repair);
  }
}
.borehole_type_no_type_mining{
  background-color: var(--triangle-color-no-type-mining);
  &:before{
    color: var(--triangle-color-no-type-mining);
  }
}

.status_ok{
  background-color: var(--color_status_ok);
  color: var(--font_color_status_ok)
}

.status_no_connection{
  background-color: var(--color_status_no_connection);
  color: var(--font_color_status_no_connection)
}

.status_stop{
  background-color: var(--color_status_stop);
  color: var(--font_color_status_stop)
}

tui-notification{
  width: 50vw;
  &._has-icon{
padding-left: 0.75rem !important;
display: flex !important;
flex-direction: row;
flex-wrap: nowrap;
align-content: center;
align-items: center;
gap: 20px !important;
justify-content: flex-start;
}
tui-svg{
position: relative !important;
top: auto;
left: 0rem !important;
  &.t-icon{
    min-width: 2rem;
  }
  &.icon{
    width: 3rem !important;
  }
  svg{
    font-size: var(--notification-label-font-size);
  }
}
}
tui-notification[data-tui-host-status="warning"]{
background-color: var(--warning-notification-color);
label.heading{
font-size: var(--notification-label-font-size);
font-style: var(--notification-label-font-style);
font-weight: var(--notification-label-font-weight);
}
div.content{
font-size: var(--notification-content-font-size);
font-style: var(--notification-content-font-style);
font-weight: var(--notification-content-font-weight);
line-height: normal;
}
tui-svg{
color: black;
}
&:after{
background-color: transparent !important;
}
}

tui-notification[data-tui-host-status="success"]{
background-color: var(--success-notification-color);
label.heading{
font-size: var(--notification-label-font-size);
font-style: var(--notification-label-font-style);
font-weight: var(--notification-label-font-weight);
}
div.content{
font-size: var(--notification-content-font-size);
font-style: var(--notification-content-font-style);
font-weight: var(--notification-content-font-weight);
line-height: normal;
}
tui-svg{
color: black;
}
&:after{
background-color: transparent !important;
}
}

tui-notification[data-tui-host-status="error"]{
background-color: var(--error-notification-color);
label.heading{
font-size: var(--notification-label-font-size);
font-style: var(--notification-label-font-style);
font-weight: var(--notification-label-font-weight);
}
div.content{
font-size: var(--notification-content-font-size);
font-style: var(--notification-content-font-style);
font-weight: var(--notification-content-font-weight);
line-height: normal;
}
tui-svg{
color: black;
}
&:after{
background-color: transparent !important;
}
}

tui-notification[data-tui-host-status="info"]{
  //background-color: var(--success-notification-color);
  label.heading{
    font-size: var(--notification-label-font-size);
    font-style: var(--notification-label-font-style);
    font-weight: var(--notification-label-font-weight);
  }
  div.content{
    font-size: var(--notification-content-font-size);
    font-style: var(--notification-content-font-style);
    font-weight: var(--notification-content-font-weight);
    line-height: normal;
  }
  tui-svg{
    color: black;
  }
  &:after{
    background-color: transparent !important;
  }
}


// используется для кнопок запущена, остановлена, нет связи в дашборде на странице месторождений с событиями
tui-wrapper[data-appearance='dashboard-btn-no-connection'] {
  background: var(--color_status_no_connection);
  color: var(--font_color_status_no_connection);
  .shadow();
  &[data-state='hovered'] {
    opacity: 0.7;
  }
  &[data-state='pressed'] {
    opacity: 0.9;
  }
  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-wrapper[data-appearance='dashboard-btn-ok'] {
  background: var(--color_status_ok);
  color: var(--font_color_status_ok);
  .shadow();
  &[data-state='hovered'] {
    opacity: 0.7;
  }
  &[data-state='pressed'] {
    opacity: 0.9;
  }
  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-wrapper[data-appearance='dashboard-btn-stop'] {
  background: var(--color_status_stop);
  color: var(--font_color_status_stop);
  .shadow();
  &[data-state='hovered'] {
    opacity: 0.7;
  }
  &[data-state='pressed'] {
    opacity: 0.9;
  }
  &[data-state='disabled'] {
    background: @disabled;
  }
}

tui-badge[data-tui-host-status="default"]{
&[data-status="no_connection"]{
background-color: var(--color_status_no_connection);
color: var(--font_color_status_no_connection)
}
}

tui-badge[data-tui-host-status="default"]{
  &[data-status="ok"]{
    background-color: var(--color_status_ok);
    color: var(--font_color_status_ok)
  }
}tui-badge[data-tui-host-status="default"]{
   &[data-status="stop"]{
     background-color: var(--color_status_stop);
     color: var(--font_color_status_stop)
   }
 }
